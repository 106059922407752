#dashboard_metrics{
    .title-section{
      padding-top: 10px;
      .btn{
        float: none;
      }
      .download-button, .invite-people{
        border-radius: 4px;
        padding: 6px 12px;
        text-transform: uppercase;
        height: 36px;
        img{
          width: 1rem;
          margin-right: 5px;
        }
      }
      .download-button{
        img{
          margin-right: 0;
        }
      }
      .list-inline{
        display: flex;
        float: right;
        li{
          margin-left: 5px;

        }
      }
    }

    .metrics-area{
        padding: 5px 0;
        margin-top: -55px;
      .metrics-area-controller{
        .row{
          margin: 0 -5px;
          [class^="col-"]{
            padding: 0 5px;
          }
        }

        padding: 0 10px;
        // border-radius: 10px;
        height: calc(100vh - 125px);
        position: relative;

        .invite-people{
          float: right;
          width: 140px;
        }

        .card{
          background-color: $white;
          padding: 10px 15px;
          border-radius: 10px;
          margin-bottom: 10px;

          .card-title-section{
            padding: 5px 15px 15px;
            width: 100%;
            clear: both;
            margin: 0 -15px;
            position: relative;
            .select-container{
              position: absolute;
              width: 125px;
              right: -20px;
              top: -3px;
            }
            .title{
              font-size: 15px;
              font-weight: 700;
              color: $dark-gray;
              text-transform: uppercase;
            }
          }
        }

        #left_metrics{
          .usage-list{
            list-style-type: none;
            padding: 0;
            margin-bottom: 0;
            > li{
              padding: 10px;
              border: 1px solid lighten($black, 95);

              h4{
                font-weight: 700;
                font-size: 18px;
                color: $dark-gray;
              }
              .list-type{
                text-transform: capitalize;
              }
              .usage-type-icon{
                background-color: $dark-gray;
                height: 48px;
                width: 48px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }
          table{
            &.legend{
              td{
                border: none;
                .legend-badge{
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin-top: 6px;
                }
              }
            }
          }

          .totalgb{
            padding-left: 8px;
            h4{
              font-size: 14px;
              font-weight: normal;
              display: block;
              text-transform: uppercase;
            }
            h2{
              font-size: 22px;
              font-weight: 700;
              text-transform: uppercase;
              span{
                color: $primary-color;
              }
            }
          }
        }
        #right_metrics{
          .nav-tabs{
            border: none;
            .nav-link{
              margin: 0 3px;
              padding: 12px 25px;
              border: none;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              text-transform: uppercase;
              background-color: $white;
              color: $dark-gray;
              font-weight: 700;
              &:first-child{
                margin-left: 10px;
              }
              &.active{
                background-color: #3e3e3e;
                color: $white;
                font-weight: 700;
                font-size: 14px;
              }
            }
          }
          .tab-content{
            height: calc(100vh - 205px);
            background: $white;
            border-radius: 10px 10px  0  0;
            position: relative;
            overflow-y: auto;

          }
          .total-entries {
            background-color: $primary-color;
            padding: 10px;
            color: $white;
            width: 100%;
            font-weight: 700;
            height: 40px;
            border-radius: 0 0  10px  10px;
          }
          .table{
            thead{
              tr {
                th{
                  background-color: #3e3e3e;
                  color: $white;
                  font-weight: 700;
                  border-top: none;
                  &:first-child{
                    border-top-left-radius: 10px;
                  }
                  &:last-child{
                    border-top-right-radius: 10px;
                  }
                }
              }
            }
            tbody{
              tr{
                background-color: $white;
                td{
                  padding: 15px 8px;

                }
              }
            }
          }
        }
      }
    }
  }
